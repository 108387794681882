.BackgroundImage {
    height: 540px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

#icons {
    display: flex;
    align-items: center;
    justify-content: center;
    background: blue;
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    width: 30px;
    height: 30px;
}