.StatusDashboard {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.side {
    text-align: start;
    flex: 1;

}

.left-side {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.right-side {
    width: 50%;
    margin-left: auto;
}

.RestrictionsSigns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.RestrictionsText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.RestrictionsText p {
    font-family: Trebuchet MS;
    font-size: 48px;
    font-weight: 800;
    letter-spacing: -1px;
    color: #000000;
    margin: 0px;
    text-align: center;
}