.StatusButton {
    padding-left: 32px;
    padding-right: 32px;
    width: 200px;
    display: flex;
}

p {
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    color: #989898;
    font-size: 22px;
    font-weight: 600;
}