html,
body,

html,
body {
  width: 100%;
  height: 100%;
  background-image: url('../public/background_logo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

}

#root {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  height: 100%;
}

.App {
  width: 1440px;
}

.App-container {
  height: 100% - 60px;
  padding-top: 40px;
  padding-bottom: 40px;
}

#footer {
  bottom: 0;
  margin-top: auto;
}